<template>
  <div class="container-fluid pb-5">
    <b-card class="af-card">
      <b-row>
        <b-col cols="7">
          <div class="bg-primary curved-edge text-white py-4 px-3">
            <h3 class="text-14 font-weight-bold text-uppercase">Network</h3>
            <p class="text-14 text-uppercase">Set airtime rates on each network</p>
          </div>
        </b-col>
        <b-col cols="5">
          <div class="bg-black curved-edge text-white py-4 px-3">
            <h3 class="text-14 font-weight-bold text-uppercase">Add Network</h3>
            <p class="text-14 text-uppercase">Edit network parameters and rates</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7" class="mt-4">
          <b-table
            id="af-table"
            :items="networks"
            :fields="fields"
            hover
            responsive
            borderless
          >
            <template v-slot:head()="data">
              <span class="text-uppercase font-weight-bold title">{{ data.label }}</span>
            </template>

            <template v-slot:head(networkName)>
              <span class="text-uppercase">Network</span>
            </template>

            <template v-slot:head(networkNumber)>
              <span class="text-uppercase">Number</span>
            </template>

            <template v-slot:cell(id)="data">
              <div class="row align-items-center no-gutters">
                <div>
                  <p class="mb-0">{{ data.index + 1 }}</p>
                </div>
              </div>
            </template>

            <template v-slot:cell(networkNumber)="data">
              <span>0{{ data.value }}</span>
            </template>

            <template v-slot:cell(networkName)="data">
              <span class="text-uppercase">{{ data.value }}</span>
            </template>

            <template v-slot:cell(networkRate)="data">
              <span>{{ data.value }}%</span>
            </template>

            <template v-slot:cell(action)="data">
              <span @click="openEditNetwork(data.item)" class="icon-pencil edit mr-3"></span>
              <span @click="openDeleteNetwork(data.item)" class="del">x</span>
            </template>
          </b-table>
        </b-col>
        <b-col cols="5" class="mt-4">
          <form @submit.prevent="addNetwork">
            <b-row class="align-items-end justify-content-between">
              <b-col cols="6" class="mt-4">
                <p class="text-muted text-14 text-uppercase">Network Name</p>
                <b-form-input v-model="newNetwork" required></b-form-input>
              </b-col>
              <b-col cols="6" class="mt-4">
                <p class="text-muted text-14 text-uppercase">Network Number</p>
                <b-form-input v-model="newNetworkNumber" required></b-form-input>
              </b-col>
              <b-col cols="6" class="mt-4">
                <p class="text-muted text-14 text-uppercase">Network Code</p>
                <b-form-input v-model="newNetworkCode" required></b-form-input>
              </b-col>
              <b-col cols="6" class="mt-4">
                <p class="text-muted text-14 text-uppercase">Network ID</p>
                <b-form-input v-model="newNetworkId" required></b-form-input>
              </b-col>
              <b-col cols="9" class="mt-4">
                <p class="text-muted text-14 text-uppercase">Network Rate</p>
                <b-form-input v-model="newNetworkRate" required></b-form-input>
              </b-col>
              <b-col cols="3" class="text-center mt-4">
                <b-button type="submit" :disabled="addingNetwork" variant="outline-primary" class="px-4">
                  <b-spinner small v-if="addingNetwork" label="Spinning"></b-spinner>
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </form>
        </b-col>
      </b-row>
    </b-card>

    <!-- edit network modal -->
    <b-modal v-model="editModalOpen" content-class="af-modal" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold mb-4">Edit Network</p>
      <b-row>
        <b-col cols="6">
          <p class="text-14 text-uppercase">Network Name</p>
          <p class="text-uppercase">{{ selectedNetworkName }}</p>
        </b-col>
        <b-col cols="6">
          <p class="text-14 text-uppercase">Network ID</p>
          <b-form-input v-model="selectedNetworkId" class="af-input-field mb-4"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <p class="text-14 text-uppercase">Network Number</p>
          <b-form-input v-model="selectedNetworkNumber" class="af-input-field mb-4"></b-form-input>
        </b-col>
        <b-col cols="4">
          <p class="text-14 text-uppercase">Network Rate</p>
          <b-form-input v-model="selectedNetworkRate" class="af-input-field mb-4"></b-form-input>
        </b-col>
        <b-col cols="4">
          <p class="text-14 text-uppercase">Network Code</p>
          <b-form-input v-model="selectedNetworkCode" class="af-input-field mb-4"></b-form-input>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button @click="updateNetwork" :disabled="updatingNetwork" variant="primary" class="px-4">
          <b-spinner small v-if="updatingNetwork" label="Spinning"></b-spinner>
          <span v-else>Update Network</span>
        </b-button>
      </div>
    </b-modal>
    <!-- edit network modal end -->
    <!-- delete network modal -->
    <b-modal v-model="deleteModalOpen" content-class="af-modal" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold text-center mb-4">Delete {{ selectedNetworkName }} Network</p>
      <p class="text-center">Are you sure you want to delete this network?</p>
      <div class="text-right">
        <b-button @click="deleteNetwork" :disabled="deletingNetwork" variant="danger" class="mt-4 px-4">
          <b-spinner small v-if="deletingNetwork" label="Spinning"></b-spinner>
          <span v-else>Delete</span>
        </b-button>
        <b-button @click="deleteModalOpen = false" :disabled="deletingNetwork" variant="empty" class="mt-4 px-4">
          <span>Cancel</span>
        </b-button>
      </div>
    </b-modal>
    <!-- delete network modal -->
  </div>
</template>

<script>
import { getNetworks, updateNetwork, addNetwork, deleteNetwork } from '../apis/network'

export default {
  metaInfo: {
    title: 'Change Rates',
    titleTemplate: '%s | AirtimeFlip'
  },
  data () {
    return {
      perPage: 10,
      networks: [],
      fields: [
        'id',
        'networkName',
        'networkNumber',
        'networkRate',
        'action'
      ],
      newNetwork: '',
      newNetworkNumber: '',
      newNetworkRate: '',
      newNetworkCode: '',
      newNetworkId: '',
      selectedNetworkName: '',
      selectedNetworkNumber: '',
      selectedNetworkRate: '',
      selectedNetworkCode: '',
      selectedNetworkId: '',
      selectedId: '',
      editModalOpen: false,
      deleteModalOpen: false,
      updatingNetwork: false,
      deletingNetwork: false,
      addingNetwork: false
    }
  },
  computed: {
    rows () {
      return this.networks.length
    }
  },
  watch: {
    editModalOpen (val) {
      if (!val) {
        this.resetSelectedNetwork()
      }
    }
  },
  created () {
    this.getNetworks()
  },
  methods: {
    openEditNetwork (network) {
      this.selectedNetworkRate = network.networkRate
      this.selectedNetworkNumber = network.networkNumber
      this.selectedId = network._id
      this.selectedNetworkName = network.networkName
      this.selectedNetworkCode = network.networkCode
      this.selectedNetworkId = network.networkId
      this.editModalOpen = true
    },
    openDeleteNetwork (network) {
      this.selectedId = network._id
      this.selectedNetworkName = network.networkName
      this.deleteModalOpen = true
    },
    resetSelectedNetwork () {
      this.selectedNetworkRate = ''
      this.selectedNetworkName = ''
      this.selectedNetworkNumber = ''
      this.selectedId = ''
      this.selectedNetworkCode = ''
    },
    async getNetworks () {
      try {
        const { data } = await getNetworks()
        this.networks = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async updateNetwork () {
      const payload = {
        networkName: this.selectedNetworkName,
        networkRate: this.selectedNetworkRate,
        networkNumber: this.selectedNetworkNumber,
        networkCode: this.selectedNetworkCode
      }
      try {
        this.updatingNetwork = true
        await updateNetwork(payload, this.selectedId)
        this.updatingNetwork = false
        this.editModalOpen = false
        this.resetSelectedNetwork()
        this.$bvToast.toast('Network updated!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getNetworks()
      } catch (error) {
        this.updatingNetwork = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async deleteNetwork () {
      try {
        this.deletingNetwork = true
        await deleteNetwork(this.selectedId)
        this.deletingNetwork = false
        this.deleteModalOpen = false
        this.resetSelectedNetwork()
        this.$bvToast.toast('Network deleted!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getNetworks()
      } catch (error) {
        this.deletingNetwork = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async addNetwork () {
      const payload = {
        networkName: this.newNetwork,
        networkRate: this.newNetworkRate,
        networkNumber: this.newNetworkNumber,
        networkCode: this.newNetworkCode,
        networkId: this.newNetworkId
      }
      try {
        this.addingNetwork = true
        await addNetwork(payload)
        this.addingNetwork = false
        this.newNetworkRate = ''
        this.newNetwork = ''
        this.newNetworkNumber = ''
        this.newNetworkId = ''
        this.newNetworkCode = ''
        this.$bvToast.toast('Network added!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getNetworks()
      } catch (error) {
        this.addingNetwork = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit {
  color: #F38D04;
  cursor: pointer;
}
.del {
  background-color: #F38D04;
  color: white;
  padding: 3px 5px;
  cursor: pointer;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
